var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.myLoading.isLoading,"is-full-page":_vm.myLoading.fullPage},on:{"update:active":function($event){return _vm.$set(_vm.myLoading, "isLoading", $event)}}}),_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Desktop")]),_c('v-expansion-panel-content',[(_vm.sliderDesktopImages)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.sliderDesktopImages,"item-key":"id","fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.uploadValue !== null)?_c('v-progress-linear',{attrs:{"value":_vm.uploadValue}}):_vm._e(),_c('div',{staticClass:"table-top-bar"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"file-input-width"},[_c('v-file-input',{attrs:{"prepend-icon":"mdi-camera","accept":"image/*","label":"File input"},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('div',{staticClass:"pl-3"},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","disabled":!_vm.image},on:{"click":_vm.uploadDesktop}},[_vm._v("Subir")])],1)])])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_c('v-img',{attrs:{"src":item.url,"aspect-ratio":"1.7"}})],1),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item, index, 'desktop')}}},[_vm._v(" mdi-delete ")]),_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-open-in-new ")])],1)],1)])]}}],null,false,53157353)}):_vm._e()],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Tablets")]),_c('v-expansion-panel-content',[(_vm.sliderTabletImages)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.sliderTabletImages,"item-key":"id","fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.uploadValueTablet !== null)?_c('v-progress-linear',{attrs:{"value":_vm.uploadValueTablet}}):_vm._e(),_c('div',{staticClass:"table-top-bar"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"file-input-width"},[_c('v-file-input',{attrs:{"prepend-icon":"mdi-camera","accept":"image/*","label":"File input"},model:{value:(_vm.imageTablet),callback:function ($$v) {_vm.imageTablet=$$v},expression:"imageTablet"}})],1),_c('div',{staticClass:"pl-3"},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","disabled":!_vm.imageTablet},on:{"click":_vm.uploadTablet}},[_vm._v("Subir")])],1)])])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_c('v-img',{attrs:{"src":item.url,"aspect-ratio":"1.7"}})],1),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item, index, 'tablet')}}},[_vm._v(" mdi-delete ")]),_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-open-in-new ")])],1)],1)])]}}],null,false,2674622825)}):_vm._e()],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Telefonos")]),_c('v-expansion-panel-content',[(_vm.sliderPhoneImages)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.sliderPhoneImages,"item-key":"id","fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.uploadValuePhone !== null)?_c('v-progress-linear',{attrs:{"value":_vm.uploadValuePhone}}):_vm._e(),_c('div',{staticClass:"table-top-bar"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"file-input-width"},[_c('v-file-input',{attrs:{"prepend-icon":"mdi-camera","accept":"image/*","label":"File input"},model:{value:(_vm.imagePhone),callback:function ($$v) {_vm.imagePhone=$$v},expression:"imagePhone"}})],1),_c('div',{staticClass:"pl-3"},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","disabled":!_vm.imagePhone},on:{"click":_vm.uploadPhone}},[_vm._v("Subir")])],1)])])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_c('v-img',{attrs:{"src":item.url,"aspect-ratio":"1.7"}})],1),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-end"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item, index, 'phone')}}},[_vm._v(" mdi-delete ")]),_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-open-in-new ")])],1)],1)])]}}],null,false,2966905097)}):_vm._e()],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }