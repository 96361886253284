<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-card
        class="mb-2"
        >
          <v-progress-linear v-if="uploadValue !== null" :value="uploadValue"></v-progress-linear>
          <loading :active.sync="loadingDesktop.isLoading" :is-full-page="loadingDesktop.fullPage"></loading>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div class="flex-grow-1">
              <v-card-title
                class="text-h5"
              >Desktop</v-card-title>

              <!-- <v-card-subtitle v-text="item.artist"></v-card-subtitle> -->
              <v-card-text>
                <v-file-input
                  chips
                  v-model="bgDesktopImage"
                  color="primary"
                  show-size
                  dense
                  prepend-icon="mdi-camera"
                ></v-file-input>
                <v-btn
                  class="subir ma-0 pa-3 white--text"
                  :disabled="bgDesktopImage == null || bgDesktopImage == ''"
                  @click="uploadDesktop()"
                  x-small
                  color="primary"
                >Subir</v-btn>
                <a v-if="bgDesktop" :href="bgDesktop.url" target="_blank" class="ml-4 ">
                  Ver imagen
                </a>
              </v-card-text>
              <!-- <v-card-actions>
              </v-card-actions> -->
            </div>

            <v-avatar
              class="ma-3"
              size="125"
              tile
            >
              <v-img class="border-1" v-if="bgDesktop" :src="bgDesktop.url"></v-img>
            </v-avatar>
          </div>
        </v-card>
        <v-card
        class="mb-2"
        >
          <v-progress-linear v-if="uploadValueTablet !== null" :value="uploadValueTablet"></v-progress-linear>
          <loading :active.sync="loadingTablet.isLoading" :is-full-page="loadingTablet.fullPage"></loading>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div class="flex-grow-1">
              <v-card-title
                class="text-h5"
              >Tablet</v-card-title>

              <!-- <v-card-subtitle v-text="item.artist"></v-card-subtitle> -->
              <v-card-text>
                <v-file-input
                  chips
                  v-model="bgTabletImage"
                  color="primary"
                  show-size
                  dense
                  prepend-icon="mdi-camera"
                ></v-file-input>
                <v-btn
                  class="subir ma-0 pa-3 white--text"
                  :disabled="bgTabletImage == null || bgTabletImage == ''"
                  @click="uploadTablet()"
                  x-small
                  color="primary"
                >Subir</v-btn>
                <a v-if="bgTablet" :href="bgTablet.url" target="_blank" class="ml-4 ">
                  Ver imagen
                </a>
              </v-card-text>
              <!-- <v-card-actions>
              </v-card-actions> -->
            </div>

            <v-avatar
              class="ma-3"
              size="125"
              tile
            >
              <v-img class="border-1" v-if="bgTablet" :src="bgTablet.url"></v-img>
            </v-avatar>
          </div>
        </v-card>
        <v-card
        class="mb-2"
        >
          <v-progress-linear v-if="uploadValuePhone !== null" :value="uploadValuePhone"></v-progress-linear>
          <loading :active.sync="loadingPhone.isLoading" :is-full-page="loadingPhone.fullPage"></loading>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div class="flex-grow-1">
              <v-card-title
                class="text-h5"
              >Phone</v-card-title>

              <!-- <v-card-subtitle v-text="item.artist"></v-card-subtitle> -->
              <v-card-text>
                <v-file-input
                  chips
                  v-model="bgPhoneImage"
                  color="primary"
                  show-size
                  dense
                  prepend-icon="mdi-camera"
                ></v-file-input>
                <v-btn
                  class="subir ma-0 pa-3 white--text"
                  :disabled="bgPhoneImage == null || bgPhoneImage == ''"
                  @click="uploadPhone()"
                  x-small
                  color="primary"
                >Subir</v-btn>
                <a v-if="bgPhone" :href="bgPhone.url" target="_blank" class="ml-4 ">
                  Ver imagen
                </a>
              </v-card-text>
              <!-- <v-card-actions>
              </v-card-actions> -->
            </div>

            <v-avatar
              class="ma-3"
              size="125"
              tile
            >
              <v-img class="border-1" v-if="bgPhone" :src="bgPhone.url"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
//import Chance from "chance";
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import Landing from "@/models/Landing.js";
export default {
  name:"LandingBackgroundImages",
  props:{
    landing:{
      required: true,
      type: Object
    },
  },
  data() {
    return {
      landingModel:new Landing,
      loading: false,
      uploadValue: null,
      uploadValueTablet: null,
      uploadValuePhone: null,
      bgDesktopImage: null,
      bgTabletImage: null,
      bgPhoneImage: null,
      bgDesktop: this.landing.backgroundDesktop,
      bgTablet: this.landing.backgroundTablet,
      bgPhone: this.landing.backgroundPhone,
      snackbar: {
        show: false,
        color: '',
        message: ''
      },
      loadingTablet:{
        isLoading:false,
        fullPage:false
      },
      loadingDesktop:{
        isLoading:false,
        fullPage:false
      },
      loadingPhone:{
        isLoading:false,
        fullPage:false
      },
    }
  },
  methods: {
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'green';
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'red';
    },
    uploadImage(path, image) {
      /*let chance = new Chance();
      let random = chance.string({
        length: 5,
        //alpha: true,
        pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      });*/
      let splitNameFile = image.name.split('.');
      let name = splitNameFile[0];
      let extension = splitNameFile[splitNameFile.length - 1];
      let fullpath = path+'/bg.'+extension;
      // let fullpath = path+'/thumb-'+name+'-'+random+'.'+extension;
      return {
        image:{
          fullpath:fullpath,
          name:name
        },
        ref:firebase.storage().ref(fullpath).put(image)
      };
    },
    uploadDesktop() {
      if (this.bgDesktopImage) {
        this.loadingDesktop.isLoading = true;
        let upload = this.uploadImage('landings/'+this.landing.id+'/desktop', this.bgDesktopImage);
        upload.ref.on(`state_changed`, snapshot=> {
            this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          },
          error => {
            console.log(error.message);
            this.showSnackbarError('Error al subir la imagen')
          },
          ()=>{
            this.uploadValue = 100;
            upload.ref.snapshot.ref.getDownloadURL().then((url)=> {
              this.bgDesktop = {url, name:upload.image.name, fullpath:upload.image.fullpath}
              //this.sliderDesktopImages.push({url, name:upload.image.name, fullpath:upload.image.fullpath})
              this.landingModel.set(
                this.landing.id,
                {backgroundDesktop: this.bgDesktop},
                { merge: true }
              ).then(() => {
                this.uploadValue = null;
                this.loadingDesktop.isLoading = false;
                this.bgDesktopImage = null;
                this.showSnackbarSuccess('La imagen se subio correctamente')
              })
              .catch(error => {
                console.log('error', error);
                this.showSnackbarError('Error al agregar la imagen en la coleccion');
              })
            });
          }
        );
      }
    },
    uploadTablet() {
      if (this.bgTabletImage) {
        this.loadingTablet.isLoading = true;
        let upload = this.uploadImage('landings/'+this.landing.id+'/tablet', this.bgTabletImage);
        upload.ref.on(`state_changed`, snapshot=> {
            this.uploadValueTablet = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          },
          error => {
            console.log(error.message);
            this.showSnackbarError('Error al subir la imagen')
          },
          ()=>{
            this.uploadValueTablet = 100;
            upload.ref.snapshot.ref.getDownloadURL().then((url)=> {
              this.bgTablet = {url, name:upload.image.name, fullpath:upload.image.fullpath}
              //this.sliderDesktopImages.push({url, name:upload.image.name, fullpath:upload.image.fullpath})
              this.landingModel.set(
                this.landing.id,
                {backgroundTablet: this.bgTablet},
                { merge: true }
              ).then(() => {
                this.uploadValueTablet = null;
                this.loadingTablet.isLoading = false;
                this.bgTabletImage = null;
                this.showSnackbarSuccess('La imagen se subio correctamente')
              })
              .catch(error => {
                console.log('error', error);
                this.showSnackbarError('Error al agregar la imagen en la coleccion');
              })
            });
          }
        );
      }
    },
    uploadPhone() {
      if (this.bgPhoneImage) {
        this.loadingPhone.isLoading = true;
        let upload = this.uploadImage('landings/'+this.landing.id+'/phone', this.bgPhoneImage);
        upload.ref.on(`state_changed`, snapshot=> {
            this.uploadValuePhone = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          },
          error => {
            console.log(error.message);
            this.showSnackbarError('Error al subir la imagen')
          },
          ()=>{
            this.uploadValuePhone = 100;
            upload.ref.snapshot.ref.getDownloadURL().then((url)=> {
              this.bgPhone = {url, name:upload.image.name, fullpath:upload.image.fullpath}
              //this.sliderDesktopImages.push({url, name:upload.image.name, fullpath:upload.image.fullpath})
              this.landingModel.set(
                this.landing.id,
                {backgroundPhone: this.bgPhone},
                { merge: true }
              ).then(() => {
                this.uploadValuePhone = null;
                this.loadingPhone.isLoading = false;
                this.bgPhoneImage = null;
                this.showSnackbarSuccess('La imagen se subio correctamente')
              })
              .catch(error => {
                console.log('error', error);
                this.showSnackbarError('Error al agregar la imagen en la coleccion');
              })
            });
          }
        );
      }
    },
  }
}
</script>

<style>
.border-1{
  border: 1px solid #000;
}
</style>