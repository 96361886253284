<template>
  <div>
    <loading :active.sync="myLoading.isLoading" :is-full-page="myLoading.fullPage"></loading>
    <v-expansion-panels
      v-model="panel"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>Desktop</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table v-if="sliderDesktopImages"
            :headers="tableHeaders"
            :items="sliderDesktopImages"
            item-key="id"
            fixed-header
            class="elevation-1"
          >
            <!-- show-select -->
            <!-- :single-select="singleSelect" -->
            <template v-slot:top>
              <v-progress-linear v-if="uploadValue !== null" :value="uploadValue"></v-progress-linear>
              <div class="table-top-bar">
                <div class="d-flex">
                  <div class="file-input-width">
                    <v-file-input prepend-icon="mdi-camera" v-model="image" accept="image/*" label="File input"></v-file-input>
                  </div>
                  <div class="pl-3">
                    <v-btn color="primary" :disabled="!image" class="mt-3" @click="uploadDesktop">Subir</v-btn>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:item="{ item, index }">
              <tr>
                <td>
                  <v-img :src="item.url" aspect-ratio="1.7"></v-img>
                </td>
                <td>
                  {{item.name}}
                </td>
                <td class="text-end">
                  <v-icon 
                    class="mr-2"
                    color="red"
                    @click="deleteItem(item, index, 'desktop')"
                  >
                    mdi-delete
                  </v-icon>
                  <a :href="item.url" target="_blank">
                    <v-icon 
                      class="mr-2"
                    >
                      mdi-open-in-new
                    </v-icon>
                  </a>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Tablets</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table v-if="sliderTabletImages"
            :headers="tableHeaders"
            :items="sliderTabletImages"
            item-key="id"
            fixed-header
            class="elevation-1"
          >
            <!-- show-select -->
            <!-- :single-select="singleSelect" -->
            <template v-slot:top>
              <v-progress-linear v-if="uploadValueTablet !== null" :value="uploadValueTablet"></v-progress-linear>
              <div class="table-top-bar">
                <div class="d-flex">
                  <div class="file-input-width">
                    <v-file-input prepend-icon="mdi-camera" v-model="imageTablet" accept="image/*" label="File input"></v-file-input>
                  </div>
                  <div class="pl-3">
                    <v-btn color="primary" :disabled="!imageTablet" class="mt-3" @click="uploadTablet">Subir</v-btn>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:item="{ item, index }">
              <tr>
                <td>
                  <v-img :src="item.url" aspect-ratio="1.7"></v-img>
                </td>
                <td>
                  {{item.name}}
                </td>
                <td class="text-end">
                  <v-icon 
                    class="mr-2"
                    color="red"
                    @click="deleteItem(item, index, 'tablet')"
                  >
                    mdi-delete
                  </v-icon>
                  <a :href="item.url" target="_blank">
                    <v-icon 
                      class="mr-2"
                    >
                      mdi-open-in-new
                    </v-icon>
                  </a>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Telefonos</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table v-if="sliderPhoneImages"
            :headers="tableHeaders"
            :items="sliderPhoneImages"
            item-key="id"
            fixed-header
            class="elevation-1"
          >
            <!-- show-select -->
            <!-- :single-select="singleSelect" -->
            <template v-slot:top>
              <v-progress-linear v-if="uploadValuePhone !== null" :value="uploadValuePhone"></v-progress-linear>
              <div class="table-top-bar">
                <div class="d-flex">
                  <div class="file-input-width">
                    <v-file-input prepend-icon="mdi-camera" v-model="imagePhone" accept="image/*" label="File input"></v-file-input>
                  </div>
                  <div class="pl-3">
                    <v-btn color="primary" :disabled="!imagePhone" class="mt-3" @click="uploadPhone">Subir</v-btn>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:item="{ item, index }">
              <tr>
                <td>
                  <v-img :src="item.url" aspect-ratio="1.7"></v-img>
                </td>
                <td>
                  {{item.name}}
                </td>
                <td class="text-end">
                  <v-icon 
                    class="mr-2"
                    color="red"
                    @click="deleteItem(item, index, 'phone')"
                  >
                    mdi-delete
                  </v-icon>
                  <a :href="item.url" target="_blank">
                    <v-icon 
                      class="mr-2"
                    >
                      mdi-open-in-new
                    </v-icon>
                  </a>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Landing from "@/models/Landing.js";
import Chance from "chance";
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

export default {
  name:"LandingSliderImages",
  props:{
    landing:{
      required: true,
      type: Object
    },
  },
  data() {
    return {
      panel: [],
      uploadValue:null,
      uploadValueTablet:null,
      uploadValuePhone:null,
      landingModel:new Landing,
      sliderDesktopImages:this.landing.sliderDesktopImages,
      sliderTabletImages:this.landing.sliderTabletImages,
      sliderPhoneImages:this.landing.sliderPhoneImages,
      image:null,
      imageTablet:null,
      imagePhone:null,
      myLoading:{
        isLoading:false,
        fullPage:false
      },
      tableHeaders:[
        {
          text: 'Imagen',
          align: 'start',
          sortable: true,
          value: 'img',
          width:'200px',
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false, align:'end' },
      ],
      snackbar: {
        show: false,
        color: '',
        message: ''
      },
    }
  },
  methods: {
    uploadImage(path, image) {
      let chance = new Chance();
      let random = chance.string({
        length: 5,
        //alpha: true,
        pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      });
      let splitNameFile = image.name.split('.');
      let name = splitNameFile[0];
      let extension = splitNameFile[splitNameFile.length - 1];
      let fullpath = path+'/slide-'+name+'-'+random+'.'+extension;
      return {
        image:{
          fullpath:fullpath,
          name:name
        },
        ref:firebase.storage().ref(fullpath).put(image)
      };
    },
    uploadDesktop() {
      if (this.image) {
        this.myLoading.isLoading = true;
        let upload = this.uploadImage('landings/'+this.landing.id+'/desktop', this.image);
        upload.ref.on(`state_changed`, snapshot=> {
            this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          },
          error => {
            console.log(error.message);
            this.showSnackbarError('Error al subir la imagen')
          },
          ()=>{
            this.uploadValue = 100;
            upload.ref.snapshot.ref.getDownloadURL().then((url)=>{
              this.sliderDesktopImages.push({url, name:upload.image.name, fullpath:upload.image.fullpath})
              this.landingModel.set(
                this.landing.id,
                {sliderDesktopImages: this.sliderDesktopImages},
                { merge: true }
              ).then(() => {
                this.uploadValue = null;
                this.myLoading.isLoading = false;
                this.image = null;
                this.showSnackbarSuccess('La imagen se subio correctamente')
              })
              .catch(error => {
                console.log('error', error);
                this.showSnackbarError('Error al agregar la imagen en la coleccion');
              })
            });
          }
        );
      }
    },
    uploadTablet() {
      if (this.imageTablet) {
        this.myLoading.isLoading = true;
        let upload = this.uploadImage('landings/'+this.landing.id+'/tablet', this.imageTablet);
        upload.ref.on(`state_changed`, snapshot=> {
            this.uploadValueTablet = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          },
          error => {
            console.log(error.message);
            this.showSnackbarError('Error al subir la imagen')
          },
          ()=>{
            this.uploadValueTablet = 100;
            upload.ref.snapshot.ref.getDownloadURL().then((url)=>{
              this.sliderTabletImages.push({url, name:upload.image.name, fullpath:upload.image.fullpath})
              this.landingModel.set(
                this.landing.id,
                {sliderTabletImages: this.sliderTabletImages},
                { merge: true }
              ).then(() => {
                this.uploadValueTablet = null;
                this.myLoading.isLoading = false;
                this.imageTablet = null;
                this.showSnackbarSuccess('La imagen se subio correctamente')
              })
              .catch(error => {
                console.log('error', error);
                this.showSnackbarError('Error al agregar la imagen en la coleccion');
              })
            });
          }
        );
      }
    },
    uploadPhone() {
      if (this.imagePhone) {
        this.myLoading.isLoading = true;
        let upload = this.uploadImage('landings/'+this.landing.id+'/phone', this.imagePhone);
        upload.ref.on(`state_changed`, snapshot=> {
            this.uploadValuePhone = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          },
          error => {
            console.log(error.message);
            this.showSnackbarError('Error al subir la imagen')
          },
          ()=>{
            this.uploadValuePhone = 100;
            upload.ref.snapshot.ref.getDownloadURL().then((url)=>{
              this.sliderPhoneImages.push({url, name:upload.image.name, fullpath:upload.image.fullpath})
              this.landingModel.set(
                this.landing.id,
                {sliderPhoneImages: this.sliderPhoneImages},
                { merge: true }
              ).then(() => {
                this.uploadValuePhone = null;
                this.myLoading.isLoading = false;
                this.imagePhone = null;
                this.showSnackbarSuccess('La imagen se subio correctamente')
              })
              .catch(error => {
                console.log('error', error);
                this.showSnackbarError('Error al agregar la imagen en la coleccion');
              })
            });
          }
        );
      }
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'green';
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'red';
    },
    deleteItem(item, index, device) {
      this.$dialog.confirm(`¿Desea eliminar la imagen <strong>${item.name}</strong>? se eliminara permanentemente`, {
        loader: true // default: false - when set to true, the proceed button shows a loader when clicked.
        // And a dialog object will be passed to the then() callback
      })
      .then((dialog) => {
        let data
        let imgs
        console.log('index', index);
        
        if (device == 'desktop') {
          imgs = [...this.sliderDesktopImages]
          imgs.splice(index, 1)
          data = {sliderDesktopImages: imgs}
        }
        if (device == 'tablet') {
          imgs = [...this.sliderTabletImages]
          imgs.splice(index, 1)
          data = {sliderTabletImages: imgs}
        }
        if (device == 'phone') {
          imgs = [...this.sliderPhoneImages]
          imgs.splice(index, 1)
          data = {sliderPhoneImages: imgs}
        }
        this.deleteImage(item.fullpath, data).then((message) => {
          console.log('message', message);
          
          dialog.close();
          if (device == 'desktop') {
            this.sliderDesktopImages = [...imgs]
          }
          if (device == 'tablet') {
            this.sliderTabletImages = [...imgs]
          }
          if (device == 'phone') {
            this.sliderPhoneImages = [...imgs]
          }
          this.showSnackbarSuccess(`La imagen se elimino correctamente`);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
          dialog.close();
          this.showSnackbarError(`Error al eliminar la imagen, por favor contacte al Administrador si el problema persiste`);
        });
      })
      .catch((e) => {
        console.log(e);
        // Triggered when cancel button is clicked
        console.log('Delete aborted');
      });
    },
    deleteImage(fullpath, data) {
      return new Promise((resolve, reject) => {
        firebase.storage().ref(fullpath).delete().then(() => {
          this.landingModel.update(this.landing.id, data)
            .then(() => resolve('ok'))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
      })
    }
  },
}
</script>

<style>
  .file-input-width{
    flex-basis: calc(100% - 200px);
  }
</style>