<template>
  <div class="tab-landing-images">
    <Loading :active="loading" :fullPage="true" />
    <portal to="title-page">
      <h1 v-if="landing">{{landing.name}}</h1>
    </portal>
    <landing-tabs v-if="landing" tab="images" :landing="landing">
      <v-tabs vertical>
        <v-tab>
          <v-icon left>
            mdi-image-multiple
          </v-icon>
          Slider
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-home-circle
          </v-icon>
          Home
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-image-outline
          </v-icon>
          Background
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <h2 class="mb-4">Imagenes del slider</h2>
              <LandingSliderImages :landing="landing"></LandingSliderImages>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <h2 class="mb-4">Thumbs para los listados</h2>
              <LandingThumbsImages :landing="landing"></LandingThumbsImages>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <h2 class="mb-4">Backgrounds</h2>
              <LandingBackgroundImages :landing="landing"></LandingBackgroundImages>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </landing-tabs>
  </div>
</template>

<script>
import Landing from "@/models/Landing.js";
import LandingTabs from "@/components/admin/LandingTabs";
import LandingSliderImages from "@/components/cms/LandingSliderImages";
import LandingThumbsImages from "@/components/cms/LandingThumbsImages";
import LandingBackgroundImages from "@/components/cms/LandingBackgroundImages";
export default {
  name: "AdminCMSLandingEditImages",
  components: { LandingTabs, LandingSliderImages, LandingThumbsImages, LandingBackgroundImages },
  data() {
    return {
      landingModel:new Landing,
      //settings: [],
      ///////////////////////////////////////////////
      landing:null,
      //refLanding:null,
      loading: false,
      //fecha: firebase.firestore.Timestamp.fromDate(new Date),
    };
  },
  mounted() {
    this.landingId = this.$route.params.id;
    this.landingModel.findByLandingId(this.landingId).onSnapshot((doc) => {
      this.landing = doc.data();
      //this.refLanding = doc.ref;
    });
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
}
</script>

<style>
.tab-landing-images .v-tabs--vertical .v-tab{
  justify-content: start;
}
</style>